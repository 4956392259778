<template>
    <div class="card">
        <div class="card-body">
            <div class="card-title">
                <div class="row">
                    <div class="col-1 text-end">◉</div>
                    <div class="col-11">利用者パスワード再発行</div>
                </div>
                <div class="card-subtitle"></div>

                <form @submit.prevent="post_form">
                    <div class="row mt-5">
                        <div class="col offset-md-1">
                            <div class="row">
                                <label class="col-md-3 col-form-label">
                                    新パスワード
                                </label>
                                <div class="col-md-5 password-input-container">
                                    <input 
                                        :type="showPassword ? 'text' : 'password'"
                                        v-model="inputPassword"
                                        class="form-control"
                                        :class="{'is-invalid': password_error_message.toString().length > 0}"
                                        minlength="6"
                                        maxlength="60"
                                        required
                                    >
                                    <span class="toggle-icon" @click="togglePasswordVisibility">
                                        <i :id="toggleIconId" :class="showPassword ? 'bi bi-eye-slash-fill' : 'bi bi-eye-fill'"></i>
                                    </span>
                                    <small class="form-text text-muted">半角英数字・記号6文字から60文字以内で入力してください</small>
                                    <div class='invalid-feedback'>{{ password_error_message }}</div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <label class="col-md-3 col-form-label">
                                    新パスワード（確認）
                                </label>
                                <div class="col-md-5 password-input-container">
                                    <input 
                                        :type="showPassword_confirmation ? 'text' : 'password'"
                                        v-model="inputPassword_confirmation"
                                        class="form-control"
                                        :class="{'is-invalid': password_error_message.toString().length > 0}"
                                        minlength="6"
                                        maxlength="60"
                                        required
                                    >
                                    <span class="toggle-icon" @click="togglePasswordVisibility_confirmation">
                                        <i :id="toggleIconId_confirmation" :class="showPassword_confirmation ? 'bi bi-eye-slash-fill' : 'bi bi-eye-fill'"></i>
                                    </span>
                                </div>
                                <div v-if="inputPassword.length >= 6 && inputPassword !== inputPassword_confirmation" class="offset-md-5 text-danger">パスワードが一致しません</div>
                            </div>
                            <div class="mt-5">
                                <div class="d-flex flex-row align-items-end">
                                    <input type="submit" class="btn btn-red" value="再発行" :disabled="isProcessing || inputPassword.length < 6 || inputPassword.length >= 6 && inputPassword !== inputPassword_confirmation">
                                    <label class="ms-2">※パスワードの再設定完了後、ご登録のメールアドレスへ通知をお送りいたします。</label>
                                </div>
                            </div>
                            <div class="flash alert" :class="flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage" role="alert">
                                {{ flashMessage }}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

</template>

<script>
import { computed, ref } from 'vue';
import axios from 'axios';

export default {
    setup(props) {
        const mode = ref(1);
        const isProcessing = ref(false);

        const showPassword = ref(false);
        const showPassword_confirmation = ref(false);
        const inputPassword = ref("");
        const inputPassword_confirmation = ref("");

        const toggleIconId = 'toggleIcon';
        const toggleIconId_confirmation  = "toggleIcon";

        const togglePasswordVisibility = () => {
            showPassword.value = !showPassword.value;
        }
        const togglePasswordVisibility_confirmation = () => {
            showPassword_confirmation.value = !showPassword_confirmation.value;
        }

        const password_error_message = ref("");

        const isValid = computed(() => {
            return inputPassword.value.length >= 6 && inputPassword.value === inputPassword_confirmation.value;
        });

        const post_form = async() => {
            password_error_message.value = "";
            if(!isValid) {
                password_error_message.value = "パスワードが一致しません";
            }
            const url = "/api/resetUserPassword";

            isProcessing.value = true;
            await axios.post(url, {
                uuid: props.uuid,
                password: inputPassword.value,
                password_confirmation: inputPassword_confirmation.value,
            }).then(res=>{
                visibleFlashMessage('パスワードの変更が完了しました。', "success");
                location.href = "/corp_login";
            }).catch(err=>{
                switch(err.response.status){
                    case 422:
                        if(err.response.data.errors.password){
                            password_error_message.value = err.response.data.errors.password[0];
                        }
                        break;
                    case 403:
                    case 400:
                        console.log(err.response);
                        if(err.response.data.uuid) {
                            visibleFlashMessage(err.response.data.uuid, 'alert');
                        }
                        break;
                    default:
                        console.log(err.response);
                }
            }).finally(fin=>{
                isProcessing.value = false;
            });
        }

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000)
        }

        return {
            mode,
            isProcessing,

            showPassword,
            showPassword_confirmation,
            inputPassword,
            inputPassword_confirmation,
            toggleIconId,
            toggleIconId_confirmation,
            password_error_message,

            togglePasswordVisibility,
            togglePasswordVisibility_confirmation,

            post_form,

            isValid,

            flashMessage,
            showFlashMessage,
            flashMessageStyle,
            visibleFlashMessage,
        }
    },
    props: {
        uuid: {
            type: String,
        },
    },
}
</script>

<style lang="scss" scoped>
.password-input-container {
  position: relative;
}

.toggle-icon {
  position: absolute;
  right: 30px;
  top: 20px;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
