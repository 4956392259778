<template>
    <div class="card">
        <div class="card-body">
            <div class="card-title">
                <div class="row">
                    <div class="col-1 text-end">◉</div>
                    <div class="col-11">会員パスワード再発行申請</div>
                </div>
                <div class="card-subtitle"></div>

                <form @submit.prevent="post_form">
                    <div class="row mt-5">
                        <div class="col-md-11 offset-md-1">
                            <div class="row">
                                <label class="col-md-3 control-label">
                                    事業形態
                                </label>
                                <div class="col-md-9">
                                    <div class="form-check form-check-inline mx-5" v-for="business_form in business_form_list" :key="business_form.code">
                                        <input type="radio" class="form-check-input" v-model="radio_business_form" :id="business_form.code" :value="business_form.code">
                                            <label class="form-check-label" :for="business_form.code">{{ business_form.name }}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label class="control-label">法人番号</label>
                                        </div>
                                        <div class="col-sm-6">
                                            <span class="badge bg-secondary" v-show="radio_business_form === 10040001">必須</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <input type="number" class="form-control form-control-lg" v-model="inputCorporateNumber" :placeholder="radio_business_form === 10040001 ? '13桁の法人番号を入力してください' : ''" :required="radio_business_form === 10040001" :disabled="radio_business_form !== 10040001" />
                                        </div>
                                        <div class="col-md-3">
                                            <input type="button" class="btn btn-black" value="法人情報取得" @click="getCorporationInfo" :disabled="String(inputCorporateNumber).length !== 13 || isProcessing || radio_business_form === 10040002" />
                                        </div>
                                    </div>
                                    <div class="row form-text">
                                        <label>法人番号を入力し『法人情報取得』ボタンを押下してください。</label>
                                        <label>※法人番号がわからない場合は、 <a href="https://www.houjin-bangou.nta.go.jp/" target="_blank">国税庁法人番号公表サイト</a> より、ご確認ください。</label>
                                        <label>※個人事業主の方は入力不要です。</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label class="control-label">法人名/屋号</label>
                                        </div>
                                        <div class="col-sm-6">
                                            <span class="badge bg-secondary">必須</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <input type="text" class="form-control form-control-lg" v-model="inputCorporateName" required/>
                                        </div>
                                    </div>
                                    <div class="row form-text">
                                        <label>※法人の方は入力は不要です。</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="d-flex flex-column">
                                                <label class="control-label">法人名/屋号</label>
                                                <label class="control-label">（フリガナ）</label>
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6"><span class="badge bg-secondary">必須</span></div> -->
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <input type="text" class="form-control form-control-lg" v-model="inputCorporateNameFurigana" maxlength="255" :class="{'is-invalid': 組織名_フリガナ_エラーメッセージ.length > 0}" />
                                            <div class="invalid-feedback">{{ 組織名_フリガナ_エラーメッセージ }}</div>
                                        </div>
                                    </div>
                                    <div class="row form-text">
                                        <!-- <label>※法人の方は入力は不要です。</label> -->
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-3">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label class="control-label">所在地</label>
                                        </div>
                                        <div class="col-sm-6">
                                            <span class="badge bg-secondary">必須</span>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-md-8">
                                    <div class="row">
                                        <label class="col-md-3">郵便番号</label>
                                        <div class="col-md-3">
                                            <input type="text" class="form-control form-control-lg" v-model="inputZip" required @blur="getAddress" :class="{'is-invalid': has郵便番号Error}" />
                                            <div class="invalid-feedback">{{ 郵便番号_エラーメッセージ }}</div>
                                        </div>
                                    </div>
                                    <div class="row form-text offset-md-3">
                                        <label>※法人の方は入力は不要です。</label>
                                    </div>
                                    <div class="row mt-3">
                                        <label class="col-md-3">都道府県</label>
                                        <div class="col-md-3">
                                            <input type="text" class="form-control form-control-lg" v-model="inputPrefecture" required/>
                                        </div>
                                    </div>
                                    <div class="row form-text offset-md-3">
                                        <label>※法人の方は入力は不要です。</label>
                                    </div>
                                    <div class="row mt-3">
                                        <label class="col-md-3">市区町村</label>
                                        <div class="col-md-6">
                                            <input type="text" class="form-control form-control-lg" v-model="inputCity" required />
                                        </div>
                                    </div>
                                    <div class="row form-text offset-md-3">
                                        <label>※法人の方は入力は不要です。</label>
                                    </div>
                                    <div class="row mt-3">
                                        <label class="col-md-3">町名番地、ビル名等</label>
                                        <div class="col-md-6">
                                            <input type="text" class="form-control form-control-lg" v-model="inputTown" required />
                                        </div>
                                    </div>
                                    <div class="row form-text offset-md-3">
                                        <label>※法人の方は入力は不要です。</label>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5">
                                <div class="d-flex flex-row align-items-end">
                                    <input type="submit" class="btn btn-red" value="送信" :disabled="isProcessing">
                                    <label class="ms-2">※パスワードリセット用のURLは、ご登録の管理者（利用者ID:00001）のメールアドレスへ送信します。</label>
                                </div>
                                <div class="mt-3 flash alert" :class="flashMessageStyle === 'success' ? 'alert-success' : 'alert-danger'" v-if="showFlashMessage">
                                    {{ flashMessage }}
                                </div>
                            </div>
                            <div class="mt-2">
                                <input type="button" class="btn btn-gray" value="キャンセル" :disabled="isProcessing" @click="back">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

</template>

<script>
import { ref } from 'vue';
import axios from 'axios';

export default {
    setup() {
        const isProcessing = ref(false);

        const radio_business_form = ref(10040001);

        const inputCorporateNumber = ref("");
        const inputCorporateName = ref("");
        const inputCorporateNameFurigana = ref("");
        const inputZip = ref("");
        const inputPrefecture = ref("");
        const inputCity = ref("");
        const inputTown = ref("");
        const prefectureCode = ref("");
        const has郵便番号Error = ref(false);
        const 郵便番号_エラーメッセージ = ref('');
        const 組織名_フリガナ_エラーメッセージ = ref("");

        const getCorporationInfo = async() => {
            const url = '/api/getNTACorporateInfo?corporate_number=' + inputCorporateNumber.value;

            await axios.get(url)
            .then(res => {
                inputCorporateName.value = res.data.name;
                inputCorporateNameFurigana.value = res.data.furigana;
                inputZip.value = res.data.postCode;
                inputPrefecture.value = res.data.prefectureName;
                inputCity.value = res.data.cityName;
                inputTown.value = res.data.streetNumber;
                prefectureCode.value = res.data.prefectureCode
            });
        }

        const post_form = async() => {
            const url = "/api/sendResetCorpPasswordMail";

            isProcessing.value = true;

            await axios.post(url, {
                組織名: inputCorporateName.value,
                組織名_フリガナ: inputCorporateNameFurigana.value,
                郵便番号: inputZip.value,
                都道府県: inputPrefecture.value,
                市区町村: inputCity.value,
                番地: inputTown.value,
            }).then(res => {
                location.href = "/complete_send_reissue_mail";
                // visibleFlashMessage('管理者宛にパスワード再登録用のメールが送信されました。', 'success');
            }).catch(err => {
                console.log(err);
                visibleFlashMessage('処理に失敗しました。入力内容を見直すか、最初から処理をやり直してください。', 'error');
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const flashMessage = ref('');
        const showFlashMessage = ref(false);
        const flashMessageStyle = ref('');

        const visibleFlashMessage = (msg, kind) => {
            showFlashMessage.value = true;
            flashMessage.value = msg;
            flashMessageStyle.value = kind;
            setTimeout(() => {
                showFlashMessage.value = false
            }, 3000);
        }

        const back = () => {
            history.back();
        }

        const getAddress = () => {
            const url = '/api/getAddressSeparate?zip=' + inputZip.value;

            axios.get(url).then(res =>{
                inputPrefecture.value = res.data.prefecture;
                inputCity.value = res.data.municipalitie;
                inputTown.value = res.data.town;
            });
        }

        return {
            isProcessing,
            radio_business_form,
            inputCorporateNumber,
            inputCorporateName,
            inputCorporateNameFurigana,
            inputZip,
            inputPrefecture,
            inputCity,
            inputTown,
            prefectureCode,
            has郵便番号Error,
            郵便番号_エラーメッセージ,
            組織名_フリガナ_エラーメッセージ,

            getCorporationInfo,
            post_form,

            flashMessage,
            showFlashMessage,
            flashMessageStyle,

            visibleFlashMessage,

            back,
            getAddress,
        }
    },
    props: {
        business_form_list: {
            type: Array,
        },
    },
}
</script>
