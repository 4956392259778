<template>
    <div class="container" v-if="showMode === 1">
        <form @submit.prevent="post_form">
            <div class="heading-red mt-3">無償会員登録（仮登録）</div>
            <div class="ms-3 my-3 d-flex flex-row border-bottom border-dark">
                <div class="col-form-label fw-bold">1.仮登録</div>
                <div class="col-form-label mx-3 color-resilience-gray">＞</div>
                <div class="col-form-label color-resilience-gray">2.仮登録完了・メール送信</div>
                <div class="col-form-label mx-3 color-resilience-gray">＞</div>
                <div class="col-form-label color-resilience-gray">3.無償会員登録</div>
                <div class="col-form-label mx-3 color-resilience-gray">＞</div>
                <div class="col-form-label color-resilience-gray">4.利用規約・無償会員規約への同意</div>
                <div class="col-form-label mx-3 color-resilience-gray">＞</div>
                <div class="col-form-label color-resilience-gray">5.入力内容の確認</div>
                <div class="col-form-label mx-3 color-resilience-gray">＞</div>
                <div class="col-form-label color-resilience-gray">6.本登録完了</div>
            </div>
            <div class="row my-5">
                <div class="col-md-1 text-end">◉</div>
                <div class="col-md-11">仮登録基本情報</div>
            </div>
            <div class="row mt-3 mb-5">
                <div class="col-md-11 offset-md-1">
                    <div class="row">
                        <label class="col-md-3 control-label">
                            事業形態
                        </label>
                        <div class="col-md-9">
                            <div class="form-check form-check-inline mx-5" v-for="business_form in business_form_list" :key="business_form.code">
                                <input type="radio" class="form-check-input" v-model="radio_business_form" :id="business_form.code" :value="business_form.code">
                                    <label class="form-check-label" :for="business_form.code">{{ business_form.name }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <label class="col-md-3 control-label">
                            法人番号<span class="badge bg-secondary ms-5" v-show="radio_business_form === 10040001">必須</span>
                        </label>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-9">
                                    <input type="number" class="form-control form-control-lg" v-model="inputCorporateNumber" :placeholder="radio_business_form === 10040001 ? '13桁の法人番号を入力してください' : ''" :required="radio_business_form === 10040001" :disabled="radio_business_form !== 10040001" />
                                </div>
                                <div class="col-md-3">
                                    <input type="button" class="btn btn-black" value="法人情報取得" @click="getCorporationInfo" :disabled="String(inputCorporateNumber).length !== 13 || isProcessing || radio_business_form === 10040002"  />
                                </div>
                            </div>
                            <div class="row form-text">
                                <label>法人番号を入力し『法人情報取得』ボタンを押下してください。</label>
                                <label>※法人番号がわからない場合は、 <a href="https://www.houjin-bangou.nta.go.jp/" target="_blank">国税庁法人番号公表サイト</a> より、ご確認ください。</label>
                                <label>※個人事業主の方は入力不要です。</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <label class="col-md-3 control-label">
                            法人名/屋号<span class="badge bg-secondary ms-5">必須</span>
                        </label>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-9">
                                    <input type="text" class="form-control form-control-lg" v-model="inputCorporateName" required maxlength="100"/>
                                </div>
                            </div>
                            <div class="row form-text">
                                <label>※法人の方は入力は不要です。</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-3">
                            <div class="row">
                                <label class="control-label">
                                    法人名/屋号<span class="badge bg-secondary ms-5">必須</span>
                                </label>
                            </div>
                            <div class="row">
                                <label class="control-label">
                                    （フリガナ）
                                </label>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-9">
                                    <input type="text" class="form-control form-control-lg" v-model="inputCorporateNameFurigana" required maxlength="255" :class="{'is-invalid': 組織名_フリガナ_エラーメッセージ.length > 0}" />
                                    <div class="invalid-feedback">{{ 組織名_フリガナ_エラーメッセージ }}</div>
                                </div>
                            </div>
                            <div class="row form-text">
                                <!-- <label>※法人の方は入力は不要です。</label> -->
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <label class="col-md-3 control-label">
                            所在地<span class="badge bg-secondary ms-5">必須</span>
                        </label>
                        <div class="col-md-8">
                            <div class="row">
                                <label class="col-md-3">郵便番号</label>
                                <div class="col-md-3">
                                    <input type="text" class="form-control form-control-lg" v-model="inputZip" required @blur="getAddress" maxlength="7" :class="{'is-invalid': has郵便番号Error}" />
                                    <div class="invalid-feedback">{{ 郵便番号_エラーメッセージ }}</div>
                                </div>
                            </div>
                            <div class="row form-text offset-md-3">
                                <label>※法人の方は入力は不要です。</label>
                            </div>
                            <div class="row mt-3">
                                <label class="col-md-3">都道府県</label>
                                <div class="col-md-3">
                                    <input type="text" class="form-control form-control-lg" v-model="inputPrefecture" required maxlength="10"/>
                                </div>
                            </div>
                            <div class="row form-text offset-md-3">
                                <label>※法人の方は入力は不要です。</label>
                            </div>
                            <div class="row mt-3">
                                <label class="col-md-3">市区町村</label>
                                <div class="col-md-6">
                                    <input type="text" class="form-control form-control-lg" v-model="inputCity" required maxlength="50"/>
                                </div>
                            </div>
                            <div class="row form-text offset-md-3">
                                <label>※法人の方は入力は不要です。</label>
                            </div>
                            <div class="row mt-3">
                                <label class="col-md-3">町名番地、ビル名等</label>
                                <div class="col-md-6">
                                    <input type="text" class="form-control form-control-lg" v-model="inputTown" required  maxlength="255"/>
                                </div>
                            </div>
                            <div class="row form-text offset-md-3">
                                <label>※法人の方は入力は不要です。</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <label class="col-md-1 control-label">
                            管理者
                        </label>
                        <label class="col-md-2 control-label">
                            氏名<span class="badge bg-secondary ms-5">必須</span>
                        </label>
                        <label class="col-md-1 control-label">
                            姓
                        </label>
                        <div class="col-md-2">
                            <input type="text" class="form-control form-control-lg" v-model="inputFamilyName" required  maxlength="255"/>
                        </div>
                        <label class="col-md-1 control-label">
                            名
                        </label>
                        <div class="col-md-2">
                            <input type="text" class="form-control form-control-lg" v-model="inputFirstName" required  maxlength="255"/>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <label class="col-md-2 control-label offset-md-1">
                            部署・役職
                            <!-- 部署・役職<span class="badge bg-secondary ms-5">必須</span> -->
                        </label>
                        <label class="col-md-1 control-label">
                            部署
                        </label>
                        <div class="col-md-2">
                            <input type="text" class="form-control form-control-lg" v-model="inputDepartment" required  maxlength="255" :disabled="checkDepartmentNothing"/>
                        </div>
                        <div class="col-md-1">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" v-model="checkDepartmentNothing" id="department-nothing" @change="changeDepartmentNothing">
                                <label class="form-check-label" for="department-nothing">
                                    なし
                                </label>
                            </div>
                        </div>
                        <label class="col-md-1 control-label">
                            役職
                        </label>
                        <div class="col-md-2">
                            <input type="text" class="form-control form-control-lg" v-model="inputPosition" required  maxlength="255" :disabled="checkClassNothing"/>
                        </div>
                        <div class="col-md-1">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" v-model="checkClassNothing" id="class-nothing" @change="changeClassNothing">
                                <label class="form-check-label" for="class-nothing">
                                    なし
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <label class="col-md-2 control-label offset-md-1">
                            メールアドレス<span class="badge bg-secondary ms-3">必須</span>
                        </label>
                        <div class="col-md-6">
                            <input type="email" class="form-control form-control-lg" v-model="inputEmail" required  maxlength="255" :class="{'is-invalid': hasメールアドレスError}" />
                            <div class="invalid-feedback">{{ メールアドレス_エラーメッセージ }}</div>
                        </div>
                    </div>
                    <div class="row form-text offset-md-3">
                        <label>半角英数字</label>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-6 offset-md-3">
                            <input type="email" class="form-control form-control-lg" v-model="inputReEmail" required  maxlength="255" :class="{'is-invalid': hasメールアドレスError}" />
                        </div>
                    </div>
                    <div class="row form-text offset-md-3">
                        <label>確認のためもう一度入力してください。</label>
                    </div>
                    <div class="row mt-3">
                        <label class="col-md-3 control-label">
                            ととのエルのことを、どこでお知りになりましたか？<span class="badge bg-secondary ms-1">必須</span>
                        </label>
                        <div class="col-md-6">
                            <select name="selectFindOut" class="form-select form-select-lg" v-model="selectFindOut" required>
                                <option type="text" v-for="find_out in find_out_list" :key="find_out.code" :value="find_out.code">{{ find_out.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row form-text mt-3 offset-md-3">
                        <label>具体的な名称又はその他の場合</label>
                    </div>
                    <div class="row">
                        <div class="col-md-6 offset-md-3">
                            <input type="text" class="form-control form-control-lg" v-model="inputFindOutOther" :required="selectFindOut === 20000009 || selectFindOut === 20009999" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row border-bottom border-2 border-color-resilience">
            </div>
            <div class="row">
                <div class="col-md-11 offset-md-1">
                    <div class="row mt-3">
                        <label>入力内容にお間違いはございませんか？</label>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-6">
                            <button type="submit" class="btn btn-inquiry-button" :disabled="isProcessing">無償会員の仮登録を申し込みます</button>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <label>「市区町村」「町名番地、ビル名等」の入力項目について、変換可能な半角文字は全角文字に変換して登録いたします。</label>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="container" v-else>
        <div class="heading-red mt-3">無償会員登録（仮登録完了・メール送信）</div>
        <div class="ms-3 my-3 d-flex flex-row border-bottom border-dark">
            <div class="col-form-label color-resilience-gray">1.仮登録</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label fw-bold">2.仮登録完了・メール送信</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">3.無償会員登録</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">4.利用規約・無償会員規約への同意</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">5.入力内容の確認</div>
            <div class="col-form-label mx-3 color-resilience-gray">＞</div>
            <div class="col-form-label color-resilience-gray">6.本登録完了</div>
        </div>
        <div class="row mt-3">
            <label>下記のメールアドレス宛に本登録のご案内を送信いたしました。メールに記載されている認証用URLをクリックして「無償会員本登録」を行ってください。</label>
        </div>
        <div class="row my-3">
            <div class="col-md-6">
                <table class="table table-bordered bg-light">
                    <tbody>
                        <tr>
                            <td>メールアドレス</td>
                            <td>{{ inputEmail }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row mt-3">
            <label>メールが届かない場合</label>
        </div>
        <div class="row mt-3">
            <div class="offset-md-1">
                <label>迷惑メールフォルダ等に振り分けられていないかご確認ください。</label>
            </div>
        </div>
        <div class="row mt-2">
            <div class="offset-md-1">
                <label>メールアドレスが誤っていた場合は、正しいメールアドレスで再度仮登録を行ってください。</label>
            </div>
        </div>
        <div class="row mt-2">
            <div class="offset-md-1">
                <label>ケータイメールアドレスの場合、ドメイン設定によりメールが受信できない場合がございます。「totono-l.org」を受信許可リストに設定のうえ、再送ボタンを押してください。</label>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-4">
                <input type="button" class="btn btn-inquiry-button" value="メールが届いていませんので再送してください" @click="sendRemail" />
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import axios from 'axios';

export default {
    setup(props) {
        const showMode = ref(0);
        const isProcessing = ref(false);

        const radio_business_form = ref(10040001);
        const inputCorporateNumber = ref('');
        const inputCorporateName = ref('');
        const inputCorporateNameFurigana = ref("");
        const inputZip = ref('');
        const inputPrefecture = ref('');
        const inputCity = ref('');
        const inputTown = ref('');
        const inputFamilyName = ref('');
        const inputFirstName = ref('');
        const inputDepartment = ref('');
        const inputPosition = ref('');
        const inputEmail = ref('');
        const inputReEmail = ref('');
        const selectFindOut = ref('');
        const inputFindOutOther = ref('');

        const prefectureCode = ref('');

        const hasメールアドレスError = ref(false);
        const メールアドレス_エラーメッセージ = ref('');

        const 郵便番号_エラーメッセージ = ref(false);
        const has郵便番号Error = ref('');

        const 組織名_フリガナ_エラーメッセージ = ref("");

        const getAddress = () => {
            const url = '/api/getAddressSeparate?zip=' + inputZip.value;

            axios.get(url).then(res =>{
                inputPrefecture.value = res.data.prefecture;
                inputCity.value = res.data.municipalitie;
                inputTown.value = res.data.town;
            });
        }

        const getCorporationInfo = () => {
            const url = '/api/getNTACorporateInfo?corporate_number=' + inputCorporateNumber.value;

            axios.get(url)
            .then(res => {
                inputCorporateName.value = res.data.name;
                inputCorporateNameFurigana.value = res.data.furigana;
                inputZip.value = res.data.postCode;
                inputPrefecture.value = res.data.prefectureName;
                inputCity.value = res.data.cityName;
                inputTown.value = res.data.streetNumber;
                prefectureCode.value = res.data.prefectureCode
            });
        }

        const temp_corp_id = ref(0);
        const token = ref('');

        const post_form = () => {
            メールアドレス_エラーメッセージ.value = '';
            hasメールアドレスError.value = false;

            郵便番号_エラーメッセージ.value = '';
            has郵便番号Error.value = false;

            組織名_フリガナ_エラーメッセージ.value = "";

            isProcessing.value = true;

            const url = '/api/register/temporaryFree';

            axios.post(url, {
                事業形態: radio_business_form.value,
                法人番号: inputCorporateNumber.value,
                組織名: inputCorporateName.value,
                組織名_フリガナ: inputCorporateNameFurigana.value,
                郵便番号: inputZip.value,
                都道府県: inputPrefecture.value,
                市区町村: inputCity.value,
                番地: inputTown.value,
                管理者名_姓: inputFamilyName.value,
                管理者名_名: inputFirstName.value,
                管理者_所属部署: inputDepartment.value,
                管理者_役職: inputPosition.value,
                管理者_メールアドレス: inputEmail.value,
                管理者_メールアドレス2: inputReEmail.value,
                アンケート: selectFindOut.value,
                アンケート_その他: inputFindOutOther.value,
                都道府県コード: prefectureCode.value,
            }).then(res => {
                showMode.value = 2;
                temp_corp_id.value = res.data.corp.id;
                token.value = res.data.token;
            }).catch(err => {
                if(err.response.status === 422){
                    if(err.response.data.errors.管理者_メールアドレス){
                        メールアドレス_エラーメッセージ.value = '入力されたメールアドレスが異なります。';
                        hasメールアドレスError.value = true;
                    }
                    if(err.response.data.errors.郵便番号){
                        郵便番号_エラーメッセージ.value = '郵便番号を確認してください。';
                        has郵便番号Error.value = true;
                    }
                    if(err.response.data.errors.組織名_フリガナ){
                        組織名_フリガナ_エラーメッセージ.value = "全角カタカナのみ入力可能です。";
                    }
                }else{
                    console.log(err.response.data);
                }
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const sendRemail = () => {
            const url = 'api/register/sendRemailFree';

            axios.post(url, {
                組織名: inputCorporateName.value,
                都道府県: inputPrefecture.value,
                市区町村: inputCity.value,
                番地: inputTown.value,
                管理者名_姓: inputFamilyName.value,
                管理者名_名: inputFirstName.value,
                管理者_所属部署: inputDepartment.value,
                管理者_役職: inputPosition.value,
                管理者_メールアドレス: inputEmail.value,
                ID: temp_corp_id.value,
                token: token.value,
            });
        }

        onMounted(() => {
            showMode.value = props.mode;
        })

        const checkDepartmentNothing = ref(false);
        const checkClassNothing = ref(false);
        const tmpDepartment = ref('');
        const tmpPosition = ref('');

        const changeDepartmentNothing = () => {
            if(checkDepartmentNothing.value){
                tmpDepartment.value = inputDepartment.value;
                inputDepartment.value = "";
            }else{
                inputDepartment.value = tmpDepartment.value;
            }
        }

        const changeClassNothing = () => {
            if(checkClassNothing.value){
                tmpPosition.value = inputPosition.value;
                inputPosition.value = "";
            }else{
                inputPosition.value = tmpPosition.value;
            }
        }

        return {
            showMode,
            isProcessing,

            radio_business_form,
            inputCorporateNumber,
            inputCorporateName,
            inputCorporateNameFurigana,
            inputZip,
            inputPrefecture,
            inputCity,
            inputTown,
            inputFamilyName,
            inputFirstName,
            inputDepartment,
            inputPosition,
            inputEmail,
            inputReEmail,
            selectFindOut,
            inputFindOutOther,

            prefectureCode,

            hasメールアドレスError,
            メールアドレス_エラーメッセージ,
            has郵便番号Error,
            郵便番号_エラーメッセージ,
            組織名_フリガナ_エラーメッセージ,

            getAddress,
            getCorporationInfo,
            post_form,

            temp_corp_id,
            token,

            sendRemail,

            changeDepartmentNothing,
            changeClassNothing,

            checkDepartmentNothing,
            checkClassNothing,
            tmpDepartment,
            tmpPosition,
        }
    },
    props: {
        business_form_list:{
            type: Array,
        },
        find_out_list:{
            type: Array,
        },
        nta_app_id:{
            type: String,
        },
        mode:{
            type: Number,
        }
    },
}
</script>