/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// require('./bootstrap');
// import './bootstrap.js';
// window.Popper = require('@popperjs/core');

// import popper from '@popperjs/core';
// window.Popper = popper;
// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

import 'bootstrap-icons/font/bootstrap-icons.css'

import { createApp } from 'vue';

import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');

import Notifications from '@kyvg/vue3-notification';
import VueScrollTo from 'vue-scrollto';
import FloatingVue from 'floating-vue';

import laravelPermissionToVuejs from 'laravel-permission-to-vuejs';

import WelcomeComponent from './components/WelcomeComponent.vue';
import BlPriceComponent from './components/bl/PriceComponent.vue';
import BlCalcPayPerUseComponent from './components/bl/CalcPayPerUseComponent.vue';
import BlAboutComponent from './components/bl/AboutComponent.vue';
import BlMainFunctionComponent from './components/bl/MainFunctionComponent.vue';
import BlCautionComponent from './components/bl/CautionComponent.vue';
import BlPrivacyPolicyComponent from './components/bl/PrivacyPolicyComponent.vue';
import BlCloudComponent from './components/bl/CloudComponent.vue';
import BlCookieComponent from './components/bl/CookieComponent.vue';
import BlNewsComponent from './components/bl/NewsComponent.vue';
import ResolveDetail1 from './components/bl/ResolveDetail1Component.vue';
import ResolveDetail2 from './components/bl/ResolveDetail2Component.vue';
import ResolveDetail3 from './components/bl/ResolveDetail3Component.vue';
import ResolveDetail4 from './components/bl/ResolveDetail4Component.vue';
import ResolveDetail5 from './components/bl/ResolveDetail5Component.vue';
import ResolveDetail6 from './components/bl/ResolveDetail6Component.vue';
import CreateCorpComponent from './components/corps/CreateCorpComponent.vue';
import IndexCorpComponent from './components/corps/IndexCorpComponent.vue';
import ShowCorpComponent from './components/corps/ShowCorpComponent.vue';
import RegisterFreeComponent from './components/register/FreeComponent.vue';
import RegisterFreeHonComponent from './components/register/FreeHonComponent.vue';
import TermsForFreeComponent from './components/register/TermsForFreeComponent.vue';
import RegisterPaidComponent from './components/register/PaidComponent.vue';
import RegisterPaidHonComponent from './components/register/PaidHonComponent.vue';
import RegisterPaidHonSuccessComponent from './components/register/PaidHonSuccessComponent.vue';
import TermsForPaidComponent from './components/register/TermsForPaidComponent.vue';
import RegisterFoundingComponent from './components/register/FoundingComponent.vue';
import RegisterFoundingHonComponent from './components/register/FoundingHonComponent.vue';
import SwitchPayMethodBeforeDepositComponent from './components/register/SwitchPayMethodBeforeDepositComponent.vue';
import SwitchPaymentBeforeDepositSuccess from './components/register/SwitchPaymentBeforeDepositSuccess.vue';
import TermsForFoundingComponent from './components/register/TermsForFoundingComponent.vue';
import SAdminHomeComponent from './components/s-admin/HomeComponent.vue';
import SAdminInternalInfoComponent from './components/s-admin/InternalInfoComponent.vue';
import SAdminInternalInfoHeaderComponent from './components/s-admin/InternalInfoHeaderComponent.vue';
import SAdminShowPastAnnualChargeComponent from './components/s-admin/ShowPastAnnualChargeComponent.vue';
import SAdminShowPastPayPerUseComponent from './components/s-admin/ShowPastPayPerUseComponent.vue';
import SAdminRegisterCorpComponent from './components/s-admin/RegisterCorpComponent.vue';
import SAdminManageGroupSupportingComponent from './components/s-admin/ManageGroupSupportingComponent.vue';
import SAdminShowGroupMemberListComponent from './components/s-admin/GroupMember/ShowGroupMemberListComponent.vue';
import SAdminShowGroupMemberComponent from './components/s-admin/GroupMember/ShowGroupMemberComponent.vue';
import SAdminCreateGroupMemberComponent from './components/s-admin/GroupMember/CreateGroupMemberComponent.vue';
import SAdminEditGroupMemberComponent from './components/s-admin/GroupMember/EditGroupMemberComponent.vue';
import SAdminShowSupportingMemberListComponent from './components/s-admin/SupportingMember/ShowListComponent.vue';
import SAdminShowNoticeHistoryComponent from './components/s-admin/ShowNoticeHistoryComponent.vue';
import SAdminShowNoticeListComponent from './components/s-admin/Notice/ShowListComponent.vue';
import SAdminEditNoticeComponent from './components/s-admin/Notice/EditComponent.vue';
import SAdminShowHistoryListComponent from './components/s-admin/History/ShowListComponent.vue';
import SAdminEditHistoryComponent from './components/s-admin/History/EditComponent.vue';
import SAdminShowPaymentStatusComponent from './components/s-admin/PaymentStatus/ShowComponent.vue';
import SAdminIssueCouponComponent from './components/s-admin/Coupon/IssueComponent.vue';
import SAdminCorpInfoDownloadComponent from './components/s-admin/CorpInfo/DownloadComponent.vue';
import SAdminCorpInfoListComponent from './components/s-admin/CorpInfo/ListComponent.vue';
import AlHomeComponent from './components/al/HomeComponent.vue';
import AlHeadCorpInfoComponent from './components/al/HeadCorpInfoComponent.vue';
import CommonUserLevelIconsComponent from './components/common/UserLevelIconsComponent.vue';
import CommonCorpInfoForBnBComponent from './components/common/CorpInfoForBnBComponent.vue';
import CommonCommaInputComponent from './components/common/CommaInputComponent.vue';
import AlReplacementComponent from './components/al/ReplacementComponent.vue';
import AlMyPageHomeComponent from './components/al/MyPage/HomeComponent.vue';
import AlInitialRegistrationComponent from './components/al/InitialRegistrationComponent.vue';
import AlInitialReplacementComponent from './components/al/Initial/ReplacementComponent.vue';
import AlInitialBranchComponent from './components/al/Initial/BranchComponent.vue';
import AlInitialShowUserListComponent from './components/al/Initial/ShowUserListComponent.vue';
import AlInitialRegisterUserComponent from './components/al/Initial/RegisterUserComponent.vue';
import AlInitialEditUserComponent from './components/al/Initial/EditUserComponent.vue';
import AlAdministratorHomeComponent from './components/al/Administrator/HomeComponent.vue';
import AlAdministratorManageMemberInfoComponent from './components/al/Administrator/ManageMemberInfoComponent.vue';
import AlAdministratorRegisterPayPerUseComponent from './components/al/Administrator/RegisterPayPerUseComponent.vue';
import AlAdministratorSubmissionContractComponent from './components/al/Administrator/SubmissionContractComponent.vue';
import AlAdministratorRegisterBranchComponent from './components/al/Administrator/RegisterBranchComponent.vue';
import AlAdministratorRegisterUserComponent from './components/al/Administrator/RegisterUserComponent.vue';
import AlAdministratorRegisterMatchingWordComponent from './components/al/Administrator/RegisterMatchingWordComponent.vue';
import AlAdministratorRenewMembershipComponent from './components/al/Administrator/RenewMembershipComponent.vue';
import AlAdministratorWithdrawalComponent from './components/al/Administrator/WithdrawalComponent.vue';
import AlAdministratorShowUserListComponent from './components/al/Administrator/ShowUserListComponent.vue';
import AlAdministratorEditUserComponent from './components/al/Administrator/EditUserComponent.vue';
import AlAdministratorShowPaymentStatusComponent from './components/al/Administrator/PaymentStatusComponent.vue';
import AlAdministratorRegisterCreditComponent from './components/al/Administrator/RegisterCreditComponent.vue';
import AlAdministratorShowGroupMemberListComponent from "./components/al/Administrator/ShowGroupMemberListComponent.vue";
import AlAdministratorShowGroupMemberBillComponent from "./components/al/Administrator/ShowGroupMemberBillComponent.vue";
import AlAdministratorSystemSettingsComponent from "./components/al/Administrator/SystemSettingsComponent.vue";
import AlAdministratorCorpInfoSettingsComponent from "./components/al/Administrator/CorpInfoSettingsComponent.vue";
import SwitchPaidComponent from'./components/register/SwitchPaidComponent.vue';
import SwitchSuccessComponent from'./components/register/SwitchSuccessComponent.vue';
import SwitchCancelComponent from'./components/register/SwitchCancelComponent.vue';
import RegisterPayPerUseSuccessComponent from './components/register/PayPerUserSuccessComponent.vue';
import AlMyPageMemberInfoComponent from'./components/al/MyPage/ShowMemberInfoComponent.vue';
import RegisterMyselfComponent from './components/al/MyPage/RegisterMyselfComponent.vue';
import RegisterMyFamilyComponent from './components/al/MyPage/RegisterMyFamilyComponent.vue';
import BCPCardComponent from './components/al/MyPage/BCPCardComponent.vue';
import BCPHomeComponent from './components/bcp/HomeComponent.vue';
import BCPFirst1Component from './components/bcp/bcp_first/1Component.vue';
import BCPFirst2Component from './components/bcp/bcp_first/2Component.vue';
import BCPFirst3Component from './components/bcp/bcp_first/3Component.vue';
import BCPFirst3aComponent from './components/bcp/bcp_first/3aComponent.vue';
import BCPFirst4Component from './components/bcp/bcp_first/4Component.vue';
import BCPFirst5Component from './components/bcp/bcp_first/5Component.vue';
import BCPFirst6Component from './components/bcp/bcp_first/6Component.vue';
import BCPFirst7Component from './components/bcp/bcp_first/7Component.vue';
import BCPFirst8Component from './components/bcp/bcp_first/8Component.vue';
import BCPFirst9Component from './components/bcp/bcp_first/9Component.vue';
import BCPFirst10Component from './components/bcp/bcp_first/10Component.vue';
import BCPFirstFormComponent from './components/bcp/bcp_first/FormComponent.vue';
import BCPSecond1Component from './components/bcp/bcp_second/1Component.vue';
import BCPSecond2Component from './components/bcp/bcp_second/2Component.vue';
import BCPSecond3Component from './components/bcp/bcp_second/3Component.vue';
import BCPSecond4Component from './components/bcp/bcp_second/4Component.vue';
import BCPSecond4DetailComponent from './components/bcp/bcp_second/4DetailComponent.vue';
import BCPSecond5Component from './components/bcp/bcp_second/5Component.vue';
import BCPSecond6Component from './components/bcp/bcp_second/6Component.vue';
import BCPSecond7Component from './components/bcp/bcp_second/7Component.vue';
import BCPSecond8Component from './components/bcp/bcp_second/8Component.vue';
import BCPSecond9Component from './components/bcp/bcp_second/9Component.vue';
import BCPSecond10Component from './components/bcp/bcp_second/10Component.vue';
import BCPSecond11Component from './components/bcp/bcp_second/11Component.vue';
import BCPSecond11DetailComponent from './components/bcp/bcp_second/11DetailComponent.vue';
import BCPSecond12Component from './components/bcp/bcp_second/12Component.vue';
import BCPSecond13Component from './components/bcp/bcp_second/13Component.vue';
import BCPBasicCompleteComponent from './components/bcp/bcp_second/BasicCompleteComponent.vue';
import BCPMiddleCompleteComponent from './components/bcp/bcp_second/MiddleCompleteComponent.vue';
import BCPAdvancedCompleteComponent from './components/bcp/bcp_second/AdvancedCompleteComponent.vue';
import BCPMultiple1Component from './components/bcp/bcp_multiple/1Component.vue';
import BCPFinancialHomeComponent from './components/bcp/financial/HomeComponent.vue';
import BCPFinancialManufacture1Component from './components/bcp/financial/Manufacture1Component.vue';
import BCPFinancialManufacture2Component from './components/bcp/financial/Manufacture2Component.vue';
import BCPFinancialManufacture3Component from './components/bcp/financial/Manufacture3Component.vue';
import BCPFinancialManufacture4Component from './components/bcp/financial/Manufacture4Component.vue';
import BCPFinancialManufacture5Component from './components/bcp/financial/Manufacture5Component.vue';
import BCPFinancialManufacture6Component from './components/bcp/financial/Manufacture6Component.vue';
import BCPFinancial18Component from './components/bcp/financial/18Component.vue';
import BCPConstruction1Component from './components/bcp/financial/Construction1Component.vue';
import BCPRetail1Component from './components/bcp/financial/Retail1Component.vue';
import BnbHomeComponent from './components/bnb/HomeComponent.vue';
import BnbNoticeComponent from './components/bnb/NoticeComponent.vue';
import Bnb1Component from './components/bnb/1Component.vue';
import Bnb2KComponent from './components/bnb/2KComponent.vue';
import Bnb3Component from './components/bnb/3Component.vue';
import Bnb4KComponent from './components/bnb/4KComponent.vue';
import Bnb9Component from './components/bnb/9Component.vue';
import BnbMatchingAnswerComponent from './components/bnb/MatchingAnswerComponent.vue';
import SafetyHomeComponent from './components/safety/HomeComponent.vue';
import SafetyK1Component from './components/safety/K1Component.vue';
import SafetySettingEditComponent from './components/safety/EditSettingComponent.vue';
import SafetyK2Component from './components/safety/K2Component.vue';
import SafetyK3Component from './components/safety/K3Component.vue';
import SafetyR1Component from './components/safety/R1Component.vue';
import SafetyR2Component from './components/safety/R2Component.vue';
import SafetyAnswerComponent from './components/safety/AnswerComponent.vue';
import SafetyShowListComponent from './components/safety/ShowListComponent.vue';
import ContactComponent from './components/al/Contact/ContactComponent.vue';
import BlContactComponent from './components/bl/Contact/ContactComponent.vue';
import BCPCheckComponent from './components/bcp/check/CheckComponent.vue';
import BCPIntroductionAComponent from './components/bcp/bcp_introduction/AComponent.vue';
import BCPIntroductionBComponent from './components/bcp/bcp_introduction/BComponent.vue';
import BCPIntroductionTutorialComponent from './components/bcp/bcp_introduction/TutorialComponent.vue';
import BCPIntroductionContentsComponent from './components/bcp/bcp_introduction/ContentsComponent.vue';
import BCPIntroduction1Component from './components/bcp/bcp_introduction/1Component.vue';
import BCPIntroduction2Component from './components/bcp/bcp_introduction/2Component.vue';
import BCPIntroduction3Component from './components/bcp/bcp_introduction/3Component.vue';
import BCPIntroduction4Component from './components/bcp/bcp_introduction/4Component.vue';
import BCPIntroduction5Component from './components/bcp/bcp_introduction/5Component.vue';
import BCPIntroduction6Component from './components/bcp/bcp_introduction/6Component.vue';
import BCPIntroduction7Component from './components/bcp/bcp_introduction/7Component.vue';
import BCPIntroduction8Component from './components/bcp/bcp_introduction/8Component.vue';
import BCPIntroductionhComponent from './components/bcp/bcp_introduction/hComponent.vue';
import BCPIntroductionh0Component from './components/bcp/bcp_introduction/h0Component.vue';
import BCPIntroductionCompleteComponent from './components/bcp/bcp_introduction/IntroductionCompleteComponent.vue';
import BCPVersionFixComponent from './components/bcp/bcp_introduction/VersionFixComponent.vue';
import BCPBrowseHomeComponent from './components/browse/HomeComponent.vue';
import BCPBrowseHComponent from './components/browse/HComponent.vue';
import BCPBrowseNComponent from './components/browse/NComponent.vue';
import FixationHomeComponent from './components/fixation/HomeComponent.vue';
import FixationFixationComponent from './components/fixation/FixationComponent.vue';
import FixationB1Component from './components/fixation/B1Component.vue';
import FixationB2Component from './components/fixation/B2Component.vue';
import FixationB3Component from './components/fixation/B3Component.vue';
import FixationB4Component from './components/fixation/B4Component.vue';
import FixationK1Component from './components/fixation/K1Component.vue';
import FixationK2Component from './components/fixation/K2Component.vue';
import FixationK3Component from './components/fixation/K3Component.vue';
import FixationK4Component from './components/fixation/K4Component.vue';
import FixationKeiComponent from './components/fixation/KeiComponent.vue';
import FixationAuditorComponent from './components/fixation/EditAuditorComponent.vue';
import FixationBiComponent from './components/fixation/BiComponent.vue';
import FixationTrainingRecordListComponent from './components/fixation/TrainingRecordListComponent.vue';
import FixationTrainingRecordDetailComponent from './components/fixation/TrainingRecordDetailComponent.vue';
import FixationParticipantDetailComponent from './components/fixation/ParticipantDetailComponent.vue';
import BCPBrowseN1Component from './components/browse/N1Component.vue';
import BCPBrowseN2Component from './components/browse/N2Component.vue';
import BCPBrowseN3Component from './components/browse/N3Component.vue';
import BCPBrowseN4Component from './components/browse/N4Component.vue';
import BCPBrowseN5Component from './components/browse/N5Component.vue';
import BCPBrowseKComponent from './components/browse/KComponent.vue';
import BCPBrowseK1Component from './components/browse/K1Component.vue';
import BCPBrowseK2Component from './components/browse/K2Component.vue';
import BCPBrowseK3Component from './components/browse/K3Component.vue';
import BCPBrowseK4aComponent from './components/browse/K4aComponent.vue';
import BCPBrowseK4bComponent from './components/browse/K4bComponent.vue';
import BCPBrowseK4cComponent from './components/browse/K4cComponent.vue';
import BCPBrowseK4dComponent from './components/browse/K4dComponent.vue';
import BCPBrowseK4eComponent from './components/browse/K4eComponent.vue';
import BCPBrowseK4fComponent from './components/browse/K4fComponent.vue';
import BCPBrowseK5Component from './components/browse/K5Component.vue';
import BCPBrowseK6Component from './components/browse/K6Component.vue';
import BCPBrowseK7Component from './components/browse/K7Component.vue';
import BCPBrowseK8Component from './components/browse/K8Component.vue';
import BCPBrowseK9Component from './components/browse/K9Component.vue';
import BCPBrowseK10Component from './components/browse/K10Component.vue';
import BCPBrowseK11Component from './components/browse/K11Component.vue';
import BCPBrowseK12Component from './components/browse/K12Component.vue';
import BCPBrowseK13Component from './components/browse/K13Component.vue';
import BCPBrowseK14Component from './components/browse/K14Component.vue';
import BCPBrowseK15Component from './components/browse/K15Component.vue';
import BCPBrowseK16Component from './components/browse/K16Component.vue';
import BCPBrowseK17Component from './components/browse/K17Component.vue';
import BCPBrowseK18Component from './components/browse/K18Component.vue';
import BCPBrowseK19Component from './components/browse/K19Component.vue';
import BCPBrowseCComponent from './components/browse/CComponent.vue';
import BCPBrowseC1aComponent from './components/browse/C1aComponent.vue';
import BCPBrowseC1bComponent from './components/browse/C1bComponent.vue';
import BCPBrowseC1cComponent from './components/browse/C1cComponent.vue';
import BCPBrowseC1dComponent from './components/browse/C1dComponent.vue';
import BCPBrowseC2Component from './components/browse/C2Component.vue';
import BCPBrowseC3Component from './components/browse/C3Component.vue';
import BCPBrowseC4Component from './components/browse/C4Component.vue';
import BCPBrowseC5Component from './components/browse/C5Component.vue';
import BCPBrowseCFinanceComponent from './components/browse/CFinanceComponent.vue';
import BCPBrowseJComponent from './components/browse/JComponent.vue';
import BCPBrowseJ1Component from './components/browse/J1Component.vue';
import BCPBrowseJ2Component from './components/browse/J2Component.vue';
import BCPBrowseJ3Component from './components/browse/J3Component.vue';
import BCPBrowseJ4Component from './components/browse/J4Component.vue';
import BCPBrowseJ5Component from './components/browse/J5Component.vue';
import BCPBrowseJ6Component from './components/browse/J6Component.vue';
import BCPBrowseJ7Component from './components/browse/J7Component.vue';
import BCPActivate1Component from './components/bcp/bcp_activate/1Component.vue';
import BCPActivateMemoComponent from './components/bcp/bcp_activate/MemoComponent.vue';
import PDCAHomeComponent from './components/pdca/HomeComponent.vue';
import PDCAJComponent from './components/pdca/JComponent.vue';
import PDCAJ1aComponent from './components/pdca/J1aComponent.vue';
import PDCAJ1bComponent from './components/pdca/J1bComponent.vue';
import PDCAJ2aComponent from './components/pdca/J2aComponent.vue';
import PDCAJ2bComponent from './components/pdca/J2bComponent.vue';
import PDCANComponent from './components/pdca/NComponent.vue';
import PDCAN1aComponent from './components/pdca/N1aComponent.vue';
import PDCAN1bComponent from './components/pdca/N1bComponent.vue';
import PDCAN2aComponent from './components/pdca/N2aComponent.vue';
import PDCAN2bComponent from './components/pdca/N2bComponent.vue';
import PDCA7Component from './components/pdca/7Component.vue';
import PDCA7aComponent from './components/pdca/7aComponent.vue';
import PDCA7bComponent from './components/pdca/7bComponent.vue';
import PDCA8Component from './components/pdca/8Component.vue';
import PDCA9Component from './components/pdca/9Component.vue';
import HeaderBCPLinkComponent from './components/header/BCPLinkComponent.vue';
import FamilyHomeComponent from './components/family/HomeComponent.vue';
import Family1Component from './components/family/Family1Component.vue';
import Family2Component from './components/family/Family2Component.vue';
import LINELinkComponent from './components/line/LinkComponent.vue';
import LINEFamilyLinkComponent from './components/line/FamilyLinkComponent.vue';
import PasswordSendMailComponent from './components/password/SendMailComponent.vue';
import ForgotPasswordCorpComponent from './components/corps/ForgotPasswordCorpComponent.vue';
import ResetPasswordCorpComponent from './components/corps/ResetPasswordCorpComponent.vue';
import ResetPasswordUserComponent from './components/user/ResetPasswordUserComponent.vue';

const app = createApp({
    components: {
        'welcome-component': WelcomeComponent,
        'bl-price-component': BlPriceComponent,
        "bl-calc-pay-per-use-component": BlCalcPayPerUseComponent,
        'bl-about-component': BlAboutComponent,
        'bl-main-function-component': BlMainFunctionComponent,
        'bl-caution-component': BlCautionComponent,
        'bl-privacy-policy-component': BlPrivacyPolicyComponent,
        'bl-cloud-component': BlCloudComponent,
        'bl-cookie-component': BlCookieComponent,
        'bl-news-component': BlNewsComponent,
        'bl-resolve-detail-1-component': ResolveDetail1,
        'bl-resolve-detail-2-component': ResolveDetail2,
        'bl-resolve-detail-3-component': ResolveDetail3,
        'bl-resolve-detail-4-component': ResolveDetail4,
        'bl-resolve-detail-5-component': ResolveDetail5,
        'bl-resolve-detail-6-component': ResolveDetail6,
        'create-corp-component': CreateCorpComponent,
        'index-corp-component' : IndexCorpComponent,
        'show-corp-component' : ShowCorpComponent,
        'register-free-component' : RegisterFreeComponent,
        'register-free-hon-component' : RegisterFreeHonComponent,
        'terms-for-free-component': TermsForFreeComponent,
        'register-paid-component' : RegisterPaidComponent,
        'register-paid-hon-component' : RegisterPaidHonComponent,
        'register-paid-hon-success-component' : RegisterPaidHonSuccessComponent,
        'switch-pay-method-before-deposit-component': SwitchPayMethodBeforeDepositComponent,
        'register-switch-payment-before-deposit-success-component': SwitchPaymentBeforeDepositSuccess,
        'terms-for-paid-component': TermsForPaidComponent,
        'register-founding-component' : RegisterFoundingComponent,
        'register-founding-hon-component' : RegisterFoundingHonComponent,
        'terms-for-founding-component': TermsForFoundingComponent,
        's-admin-home-component' : SAdminHomeComponent,
        's-admin-internal-info-component' : SAdminInternalInfoComponent,
        's-admin-internal-info-header-component': SAdminInternalInfoHeaderComponent,
        's-admin-show-past-annual-charge-component': SAdminShowPastAnnualChargeComponent,
        's-admin-show-past-pay-per-use-component': SAdminShowPastPayPerUseComponent,
        's-admin-register-corp-component': SAdminRegisterCorpComponent,
        's-admin-manage-group-supporting-component' : SAdminManageGroupSupportingComponent,
        's-admin-show-group-member-list-component' : SAdminShowGroupMemberListComponent,
        's-admin-show-group-member-component' : SAdminShowGroupMemberComponent,
        's-admin-create-group-member-component' : SAdminCreateGroupMemberComponent,
        's-admin-edit-group-member-component' : SAdminEditGroupMemberComponent,
        's-admin-show-supporting-member-list-component': SAdminShowSupportingMemberListComponent,
        's-admin-show-notice-history-component': SAdminShowNoticeHistoryComponent,
        's-admin-show-notice-list-component': SAdminShowNoticeListComponent,
        's-admin-edit-notice-component': SAdminEditNoticeComponent,
        's-admin-show-history-list-component': SAdminShowHistoryListComponent,
        's-admin-edit-history-component': SAdminEditHistoryComponent,
        's-admin-show-payment-status-component': SAdminShowPaymentStatusComponent,
        's-admin-issue-coupon-component': SAdminIssueCouponComponent,
        's-admin-corp-download-component': SAdminCorpInfoDownloadComponent,
        's-admin-corp-list-component': SAdminCorpInfoListComponent,
        'al-home-component': AlHomeComponent,
        'al-head-corp-info-component': AlHeadCorpInfoComponent,
        'al-replacement-component': AlReplacementComponent,
        'al-initial-registration-component': AlInitialRegistrationComponent,
        'al-initial-replacement-component': AlInitialReplacementComponent,
        'al-initial-branch-component': AlInitialBranchComponent,
        'al-initial-show-user-list-component': AlInitialShowUserListComponent,
        'al-initial-register-user-component': AlInitialRegisterUserComponent,
        'al-initial-edit-user-component': AlInitialEditUserComponent,
        'common-user-level-icons-component': CommonUserLevelIconsComponent,
        'common-corp-info-for-bnb-component': CommonCorpInfoForBnBComponent,
        'common-comma-input-component': CommonCommaInputComponent,
        'al-my-page-home-component': AlMyPageHomeComponent,
        'al-administrator-home-component': AlAdministratorHomeComponent,
        'al-administrator-manage-member-info-component': AlAdministratorManageMemberInfoComponent,
        'al-administrator-register-pay-per-use-component': AlAdministratorRegisterPayPerUseComponent,
        'al-administrator-submission-contract-component': AlAdministratorSubmissionContractComponent,
        'al-administrator-register-branch-component': AlAdministratorRegisterBranchComponent,
        'al-administrator-register-user-component': AlAdministratorRegisterUserComponent,
        'al-administrator-register-matching-word-component': AlAdministratorRegisterMatchingWordComponent,
        'al-administrator-renew-membership-component': AlAdministratorRenewMembershipComponent,
        'al-administrator-withdrawal-component': AlAdministratorWithdrawalComponent,
        'al-administrator-show-user-list-component': AlAdministratorShowUserListComponent,
        'al-administrator-edit-user-component': AlAdministratorEditUserComponent,
        'al-administrator-show-payment-status-component': AlAdministratorShowPaymentStatusComponent,
        "al-administrator-register-credit-component": AlAdministratorRegisterCreditComponent,
        "al-administrator-show-group-member-list-component": AlAdministratorShowGroupMemberListComponent,
        "al-administrator-show-group-member-bill-component": AlAdministratorShowGroupMemberBillComponent,
        "al-administrator-system-settings-component": AlAdministratorSystemSettingsComponent,
        "al-administrator-corp-info-settings-component": AlAdministratorCorpInfoSettingsComponent,
        'switch-paid-component': SwitchPaidComponent,
        'register-switch-success-component': SwitchSuccessComponent,
        'register-switch-cancel-component': SwitchCancelComponent,
        'al-my-page-show-member-info-component': AlMyPageMemberInfoComponent,
        'al-my-page-register-myself-component': RegisterMyselfComponent,
        'al-my-page-register-my-family-component': RegisterMyFamilyComponent,
        'al-my-page-bcp-card-component': BCPCardComponent,
        'bcp-home-component': BCPHomeComponent,
        'bcp-first-1-component': BCPFirst1Component,
        'bcp-first-2-component': BCPFirst2Component,
        'bcp-first-3-component': BCPFirst3Component,
        'bcp-first-3a-component': BCPFirst3aComponent,
        'bcp-first-4-component': BCPFirst4Component,
        'bcp-first-5-component': BCPFirst5Component,
        'bcp-first-6-component': BCPFirst6Component,
        'bcp-first-7-component': BCPFirst7Component,
        'bcp-first-8-component': BCPFirst8Component,
        'bcp-first-9-component': BCPFirst9Component,
        'bcp-first-10-component': BCPFirst10Component,
        'bcp-first-form-component': BCPFirstFormComponent,
        'bcp-second-1-component': BCPSecond1Component,
        'bcp-second-2-component': BCPSecond2Component,
        'bcp-second-3-component': BCPSecond3Component,
        'bcp-second-4-component': BCPSecond4Component,
        'bcp-second-4-detail-component': BCPSecond4DetailComponent,
        'bcp-second-5-component': BCPSecond5Component,
        'bcp-second-6-component': BCPSecond6Component,
        'bcp-second-7-component': BCPSecond7Component,
        'bcp-second-8-component': BCPSecond8Component,
        'bcp-second-9-component': BCPSecond9Component,
        'bcp-second-10-component': BCPSecond10Component,
        'bcp-second-11-component': BCPSecond11Component,
        'bcp-second-11-detail-component': BCPSecond11DetailComponent,
        'bcp-second-12-component': BCPSecond12Component,
        'bcp-second-13-component': BCPSecond13Component,
        'bcp-basic-complete-component': BCPBasicCompleteComponent,
        'bcp-middle-complete-component': BCPMiddleCompleteComponent,
        'bcp-advanced-complete-component': BCPAdvancedCompleteComponent,
        'bcp-multiple-1-component': BCPMultiple1Component,
        'bcp-financial-home-component': BCPFinancialHomeComponent,
        'bcp-financial-manufacture-1-component': BCPFinancialManufacture1Component,
        'bcp-financial-manufacture-2-component': BCPFinancialManufacture2Component,
        'bcp-financial-manufacture-3-component': BCPFinancialManufacture3Component,
        'bcp-financial-manufacture-4-component': BCPFinancialManufacture4Component,
        'bcp-financial-manufacture-5-component': BCPFinancialManufacture5Component,
        'bcp-financial-manufacture-6-component': BCPFinancialManufacture6Component,
        'bcp-financial-18-component': BCPFinancial18Component,
        'bcp-financial-construction-1-component': BCPConstruction1Component,
        'bcp-financial-retail-1-component': BCPRetail1Component,
        'bnb-home-component': BnbHomeComponent,
        'bnb-home-notice-component': BnbNoticeComponent,
        'bnb-1-component': Bnb1Component,
        'bnb-2k-component': Bnb2KComponent,
        'bnb-3-component': Bnb3Component,
        'bnb-4k-component': Bnb4KComponent,
        'bnb-9-component': Bnb9Component,
        'bnb-matching-answer-component': BnbMatchingAnswerComponent,
        'safety-home-component': SafetyHomeComponent,
        'safety-k1-component': SafetyK1Component,
        'safety-edit-setting-component': SafetySettingEditComponent,
        'safety-k2-component': SafetyK2Component,
        'safety-k3-component': SafetyK3Component,
        'safety-r1-component': SafetyR1Component,
        'safety-r2-component': SafetyR2Component,
        'safety-answer-component': SafetyAnswerComponent,
        'safety-show-list-component': SafetyShowListComponent,
        'contact-component': ContactComponent,
        'bl-contact-component': BlContactComponent,
        'bcp-check-component': BCPCheckComponent,
        'bcp-introduction-a-component': BCPIntroductionAComponent,
        'bcp-introduction-b-component': BCPIntroductionBComponent,
        'bcp-introduction-tutorial-component': BCPIntroductionTutorialComponent,
        'bcp-introduction-contents-component': BCPIntroductionContentsComponent,
        'bcp-introduction-1-component': BCPIntroduction1Component,
        'bcp-introduction-2-component': BCPIntroduction2Component,
        'bcp-introduction-3-component': BCPIntroduction3Component,
        'bcp-introduction-4-component': BCPIntroduction4Component,
        'bcp-introduction-5-component': BCPIntroduction5Component,
        'bcp-introduction-6-component': BCPIntroduction6Component,
        'bcp-introduction-7-component': BCPIntroduction7Component,
        'bcp-introduction-8-component': BCPIntroduction8Component,
        'bcp-introduction-h-component': BCPIntroductionhComponent,
        'bcp-introduction-h0-component': BCPIntroductionh0Component,
        'bcp-introduction-complete-component': BCPIntroductionCompleteComponent,
        'bcp-version-fix-component': BCPVersionFixComponent,
        'bcp-browse-home-component': BCPBrowseHomeComponent,
        'bcp-browse-h-component': BCPBrowseHComponent,
        'bcp-browse-n-component': BCPBrowseNComponent,
        'fixation-home-component': FixationHomeComponent,
        'fixation-fixation-component': FixationFixationComponent,
        'fixation-b1-component': FixationB1Component,
        'fixation-b2-component': FixationB2Component,
        'fixation-b3-component': FixationB3Component,
        'fixation-b4-component': FixationB4Component,
        'fixation-k1-component': FixationK1Component,
        'fixation-k2-component': FixationK2Component,
        'fixation-k3-component': FixationK3Component,
        'fixation-k4-component': FixationK4Component,
        'fixation-kei-component': FixationKeiComponent,
        'fixation-auditor-component': FixationAuditorComponent,
        'fixation-bi-component': FixationBiComponent,
        'fixation-training-record-list-component': FixationTrainingRecordListComponent,
        'fixation-training-record-detail-component': FixationTrainingRecordDetailComponent,
        'fixation-participant-detail-component': FixationParticipantDetailComponent,
        'bcp-browse-n1-component': BCPBrowseN1Component,
        'bcp-browse-n2-component': BCPBrowseN2Component,
        'bcp-browse-n3-component': BCPBrowseN3Component,
        'bcp-browse-n4-component': BCPBrowseN4Component,
        'bcp-browse-n5-component': BCPBrowseN5Component,
        'bcp-browse-k-component': BCPBrowseKComponent,
        'bcp-browse-k1-component': BCPBrowseK1Component,
        'bcp-browse-k2-component': BCPBrowseK2Component,
        'bcp-browse-k3-component': BCPBrowseK3Component,
        'bcp-browse-k4a-component': BCPBrowseK4aComponent,
        'bcp-browse-k4b-component': BCPBrowseK4bComponent,
        'bcp-browse-k4c-component': BCPBrowseK4cComponent,
        'bcp-browse-k4d-component': BCPBrowseK4dComponent,
        'bcp-browse-k4e-component': BCPBrowseK4eComponent,
        'bcp-browse-k4f-component': BCPBrowseK4fComponent,
        'bcp-browse-k5-component': BCPBrowseK5Component,
        'bcp-browse-k6-component': BCPBrowseK6Component,
        'bcp-browse-k7-component': BCPBrowseK7Component,
        'bcp-browse-k8-component': BCPBrowseK8Component,
        'bcp-browse-k9-component': BCPBrowseK9Component,
        'bcp-browse-k10-component': BCPBrowseK10Component,
        'bcp-browse-k11-component': BCPBrowseK11Component,
        'bcp-browse-k12-component': BCPBrowseK12Component,
        'bcp-browse-k13-component': BCPBrowseK13Component,
        'bcp-browse-k14-component': BCPBrowseK14Component,
        'bcp-browse-k15-component': BCPBrowseK15Component,
        'bcp-browse-k16-component': BCPBrowseK16Component,
        'bcp-browse-k17-component': BCPBrowseK17Component,
        'bcp-browse-k18-component': BCPBrowseK18Component,
        'bcp-browse-k19-component': BCPBrowseK19Component,
        'bcp-browse-c-component': BCPBrowseCComponent,
        'bcp-browse-c1a-component': BCPBrowseC1aComponent,
        'bcp-browse-c1b-component': BCPBrowseC1bComponent,
        'bcp-browse-c1c-component': BCPBrowseC1cComponent,
        'bcp-browse-c1d-component': BCPBrowseC1dComponent,
        'bcp-browse-c2-component': BCPBrowseC2Component,
        'bcp-browse-c3-component': BCPBrowseC3Component,
        'bcp-browse-c4-component': BCPBrowseC4Component,
        'bcp-browse-c5-component': BCPBrowseC5Component,
        'bcp-browse-cfinance-component': BCPBrowseCFinanceComponent,
        'bcp-browse-j-component': BCPBrowseJComponent,
        'bcp-browse-j1-component': BCPBrowseJ1Component,
        'bcp-browse-j2-component': BCPBrowseJ2Component,
        'bcp-browse-j3-component': BCPBrowseJ3Component,
        'bcp-browse-j4-component': BCPBrowseJ4Component,
        'bcp-browse-j5-component': BCPBrowseJ5Component,
        'bcp-browse-j6-component': BCPBrowseJ6Component,
        'bcp-browse-j7-component': BCPBrowseJ7Component,
        'bcp-activate-1-component': BCPActivate1Component,
        'bcp-activate-memo-component': BCPActivateMemoComponent,
        'pdca-home-component': PDCAHomeComponent,
        'pdca-j-component': PDCAJComponent,
        'pdca-j1a-component': PDCAJ1aComponent,
        'pdca-j1b-component': PDCAJ1bComponent,
        'pdca-j2a-component': PDCAJ2aComponent,
        'pdca-j2b-component': PDCAJ2bComponent,
        'pdca-n-component': PDCANComponent,
        'pdca-n1a-component': PDCAN1aComponent,
        'pdca-n1b-component': PDCAN1bComponent,
        'pdca-n2a-component': PDCAN2aComponent,
        'pdca-n2b-component': PDCAN2bComponent,
        'pdca-7-component': PDCA7Component,
        'pdca-7a-component': PDCA7aComponent,
        'pdca-7b-component': PDCA7bComponent,
        'pdca-8-component': PDCA8Component,
        'pdca-9-component': PDCA9Component,
        'header-bcp-link-component': HeaderBCPLinkComponent,
        'family-home-component': FamilyHomeComponent,
        'family-1-component': Family1Component,
        'family-2-component': Family2Component,
        'line-link-component': LINELinkComponent,
        'line-family-link-component': LINEFamilyLinkComponent,
        "register-pay-per-use-success-component": RegisterPayPerUseSuccessComponent,
        'password-send-mail-component': PasswordSendMailComponent,
        'forgot-password-corp-component': ForgotPasswordCorpComponent,
        'reset-password-corp-component': ResetPasswordCorpComponent,
        "reset-password-user-component": ResetPasswordUserComponent,
    },
    data() {
        return {
            dayjs: ','
        }
    },
    created(){
        this.dayjs = dayjs;
    }
});
app.use(Notifications);
app.use(VueScrollTo, {
    duration: 500, // スクロールアニメーションの長さ（ミリ秒）
    easing: "ease-in-out", // 使用されるイージング
    force: true,   // スクロールターゲットがすでに表示されている場合でも、スクロールを実行するかどうか。
    cancelable: false // ユーザーがスクロールをキャンセルできるかどうか
});
app.use(FloatingVue, {
    themes: {
        'hint-theme': {
            triggers: ['click', 'touch'],
            autoHide: true,
            placement: 'bottom',
            autoBoundaryMaxSize: true,
        },
        'browsing_restrictions': {
            triggers: ['click', 'touch'],
            autoHide: true,
            placement: 'bottom',
        },
    },
});
app.use(laravelPermissionToVuejs);
app.provide('dayjs', dayjs)
app.mount('#app');
// window.Vue = require('vue').default;


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);
// Vue.component('file-upload-component', require('./components/FileUploadComponent.vue').default);
// Vue.component('create-corp-component', require('./components/corps/CreateCorpComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app',
// });

// const fileUpload = new Vue({
//     el: '#fileUpload',
// });
